body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  --defaultRedColor: #bf2e1b;
  --defaultLightColor: #fdf8f8;
  --defaultLightColor2: #f2d5d1;
  --defaultLightPink: #FFE3DF;
  --defaultGreenColor: #00AE2F --defaultGrayDark: #16242f;

}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: var(--defaultRedColor)
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: var(--defaultLightColor2);
  border-radius: 2px;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.ant-form-item .ant-form-item-explain-error {
  text-align: start;
}

.ant-menu-item-selected {
  background: #f2d5d1 !important;
  color: var(--defaultRedColor) !important;
  font-weight: 600;
}

.ant-menu-inline .ant-menu-item {
  border-radius: initial !important;
  margin-inline: 0px !important;
}

.ant-menu-inline .ant-menu-item {
  background-color: var(--defaultLightColor);
  /* color: #e4a79f; */
}

.ant-table-thead .ant-table-cell {
  background-color: var(--defaultLightColor2) !important;
}

.ant-table-cell::before {
  background-color: transparent !important;
}

.ant-switch.ant-switch-checked {
  background: #00AE2F !important;
}

.ant-switch {
  background: var(--defaultRedColor);
}

.ant-breadcrumb .ant-breadcrumb-separator {
  font-size: 1.5rem;
}

.ant-modal-root .ant-modal-mask {
  background: linear-gradient(to right, #00235D, #BF2E1A, #241C24);
  opacity: 0.85;
}

.ant-pagination .ant-pagination-item-active {
  background-color: var(--defaultRedColor);
  color: #fff;
  border: none;
}

.ant-collapse {
  background-color: var(--defaultLightColor2) !important;
  border: none !important;
  box-shadow: 0px 3px 4px 1px #dbdbdb;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  border: 1.5px dashed var(--defaultRedColor);
  height: 110px;
  width: 116px !important;
}

.ant-upload-list-item-container {
  height: 110px !important;
  width: 116px !important;
}

.imageUploader .ant-tooltip {
  display: none;
}

span.anticon.anticon-eye {
  display: none;
}

.ant-upload-list-item.ant-upload-list-item-error {
  border-color: var(--defaultLightPink) !important;
}

.ant-layout .ant-layout-sider-light .ant-layout-sider-trigger {
  display: none;
}

.selectReact__control {
  background-color: #FFFFFF;
  box-shadow: 0px 5px 10px #1111110D !important;
  border-radius: 10px !important;
  opacity: 1;
  height: 45px;
  border: 2px solid #BF2E1A26 !important;
  width: 250px;
}