* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  --defaultRedColor: #bf2e1b;
  --defaultGrayDark: #16242f;
  --defaultTextColor: #1E1E1E;
  --defaultLightColor: #fdf8f8;
  --defaultLightPink: #FFE3DF;
}

.splash_screen {
  height: 100vh;
  background-image: url('./assets/background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_image {
  width: 50%;
}

.login_main_div {
  background-color: #FBF9FF;
  width: 600px;
  border-radius: 12px;
  height: 605px;
}

.greyBox {
  background-color: var(--defaultGrayDark);
  height: 160px;
  border-radius: 20px;
}

.login_form {
  width: 330px;
}

.input_box {
  background-color: #FFFFFF;
  box-shadow: 0px 5px 10px #1111110D;
  border-radius: 10px;
  opacity: 1;
  height: 45px;
}

.LinkStyle {
  text-decoration: none;
  color: var(--defaultRedColor);
  font-style: italic;
}

.LinkStyle:hover {
  color: var(--defaultRedColor)
}

.auth_button {
  background-color: var(--defaultRedColor);
  color: #fff;
  width: 85%;
  height: 46px;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 12px;
}

.otp_container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.otp_input_container {
  margin-top: 0rem !important;
  margin: 0.8rem;
  padding: 1rem;
  width: 3.5rem !important;
  height: 3.5rem !important;
  font-size: 2rem;
  color: #1E1E1E;
  border: none !important;
  border-color: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 0.2rem;
}

.header {
  background-color: var(--defaultGrayDark);
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 999;
}

.header_content {
  width: 93%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_style {
  text-align: center;
  color: rgb(255, 255, 255);
  background: url('./assets/backgrounContent.png');
  height: 90vh;
  overflow-y: auto;
  padding: 30px 50px;
  position: relative;

}

.toggling_sider {
  box-shadow: 5px 0px 10px #2653A20D;
  min-width: 225px !important;
}

/* home */
.home_card_div {
  background-color: #FFFFFF;
  box-shadow: 0px 5px 10px #BF2E1A0D;
  border-radius: 10px;
  opacity: 1;
  /* min-height: 50px; */
  min-height: 180px;
}

.refresh_antIcon {
  color: #518ff4;
  font-size: 20px;
  font-weight: 800;
}

.new_userDiv {
  background-color: #fff;
  border-radius: 12px;
  min-height: 710px;
}

/* users */
.search_div {
  width: 300px;
}

.search_common {
  border: 1.5px solid #BF2E1A66;
  background-color: #fff;
  height: 40px;
  border-radius: 9px;
}

.delete_button {
  background-color: var(--defaultRedColor);
  color: #fff;
  width: 120px;
}

.active {
  color: #000;
  font-weight: bold;
  font-size: 1.4rem;
  font-weight: 600;
}

.unactive {
  font-size: 1.4rem;
  color: grey;
  cursor: pointer;
  font-weight: 600;
}

.detail_card {
  background-color: #ffff;
  min-height: 400px;
  width: 600px;
  border-radius: 12px;
  text-align: start;
}

.event_card {
  background-color: #fff;
  height: 620px;
  width: 100%;
  border-radius: 12px;
}

.event_backImage {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  border-radius: 12px;
}

.event_date {
  font-size: 0.9rem;
  font-style: italic;
  font-weight: 600;
  color: var(--defaultRedColor);
}

.catgory_box {
  box-shadow: 0px 5px 10px #11111108;
  border-radius: 10px;
  opacity: 1;
}

.uploader {
  /* position: relative; */
  border: 2px dashed var(--defaultRedColor);
  /* height: 140px; */
  /* width: 300px; */
  background-color: var(--defaultLightColor);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.couponCard {
  box-shadow: 0px 10px 15px #BF2E1A0D;
  border-radius: 10px;
  opacity: 1;
  background-color: #fff;
  min-height: 180px;
  text-align: start;
}

.view_list {
  text-decoration: underline;
  color: var(--defaultRedColor);
}

.basic-multi-select {
  color: #000;
  text-align: left;
}

.donation_main {
  background-image: url('./assets/backgrounContent.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}

.donation_card {
  min-height: 100px;
  /* background-color: #fff; */
  cursor: pointer;
  box-shadow: 0px 5px 10px #1111110D;
  border-radius: 12px;
}

.donation_main .ant-image .ant-image-img {
  width: auto !important;
}